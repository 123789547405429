@charset "utf-8";
@use "../index" as *;

.c-message {
    max-width: 430px;
    height: 30px;
    margin: 0 auto 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    font-size: 14px;
    border: 1px solid $main-color;
    &--save {
        color: $main-color;
    }
    &--delete {
        color: $sub-color;
        border-color: $sub-color;
    }
}
