@charset "utf-8";
@use "../../index" as *;

// Pager
.c-articlePager {
    text-align: center;

    li {
        width: 24px;
        height: 24px;
        display: inline-block;
        letter-spacing: normal;
        vertical-align: middle;

        font-size: 14px;
        margin: 0 5px;

        a,
        span {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;

            @include mq(sp) {
                padding: 5px;
            }

            &.current {
                background-color: $main-color;
                border-radius: 50%;
                color: #fff;
            }

            &.prev,
            &.next,
            &.first,
            &.end {
                height: 24px;
                background-repeat: no-repeat;
                background-size: auto 24px;
                background-position: center center;
                @include mq {
                    background-size: 20px 20px;
                }
            }

            &.prev {
                background-image: url(../img/arrow/arrow_left.svg);
            }

            &.next {
                background-image: url(../img/arrow/arrow_right.svg);
            }

            &.first {
                background-image: url(../img/arrow/arrow_first.svg);
            }

            &.end {
                background-image: url(../img/arrow/arrow_end.svg);
            }
        }
    }
}
