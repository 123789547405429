@charset "utf-8";
@use "../index" as *;

a.c-btn,
button.c-btn {
    // リンク
    display: inline-block;
    width: auto;
    min-width: 220px;
    max-width: 100%;
    text-align: center;
    padding: 12px 50px;
    font-weight: bold;
    border-radius: 48px;
    color: #fff;
    background-color: #3b6bd3;

    &:hover {
        opacity: 0.8;
    }

    &--red {
        background-color: $sub-color;
    }
}
.c-btn--delete {
    margin-left: auto;
    display: flex;
    align-items: center;
    cursor: pointer;
    &::before {
        width: 30px;
        height: 30px;
        content: "";
        background: url(../img/common/icon-delete.svg) no-repeat center;
        background-size: contain;
        margin-right: 4px;
    }
}

.c-btn--return {
}

.c-btnIcon {
    width: 30px;
    height: 30px;
    display: block;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    cursor: pointer;
    @include mq {
        width: 24px;
        height: 24px;
    }
    &--statistics {
        background-image: url(../img/common/icon-statistics.svg);
    }
    &--edit {
        background-image: url(../img/common/icon-edit.svg);
    }
    &--delete {
        background-image: url(../img/common/icon-delete.svg);
    }
}

a.c-btnBdr,
button.c-btnBdr {
    text-decoration: underline;
    display: inline-block;
    margin-top: 20px;
    font-size: 14px;
    &--bl {
        color: $main-color;
    }
    &--red {
        color: $sub-color;
    }
}
