@charset "utf-8";
@use "../index" as *;

.c-listTable-wrapper {
    margin-top: 10px;
}
.c-listTable {
    background-color: #fff;
    margin: 0 auto;
    min-width: 892px;
    table-layout: fixed;

    .w-50 {
        width: 50px;
        // @include mq {
        //     width: 50px;
        // }
    }
    .w-90 {
        width: 90px;
        @include mq {
            width: 70px;
        }
    }
    .w-100 {
        width: 100px;
    }
    .w-180 {
        width: 180px;
    }
    .w-200 {
        width: 200px;
    }
    .w-240 {
        width: 240px;
    }
    &__head {
        background-color: $bg-color;
        th {
            text-align: left;
            padding: 11px 8px;
            font-size: 14px;
            @include mq {
                font-size: 12px;
            }
        }
    }
    td {
        padding: 5px 10px;
        font-size: 13px;
        line-height: 1.45;
        overflow-wrap: break-word;
        word-wrap: break-word;
        white-space: normal;
        border-right: 1px solid #ddd;
        @include mq {
            font-size: 11px;
        }
        &:nth-child(n + 7) {
            text-align: center;
        }
        a {
            color: $main-color;
            text-decoration: underline;
        }
    }
    tr:nth-child(2n + 1) td {
        background-color: #f7f7f7;
    }
}

.scroll-hint-icon {
    height: 90px !important;
}

.toggle-button {
    display: flex;
    align-items: center;
    position: relative;
    width: 150px;
    height: 30px;
    border-radius: 50px;
    box-sizing: content-box;
    background-color: #fff;
    cursor: pointer;
    border: 1px solid #b4b4b4;
    z-index: 0;
    &::before,
    &::after {
        position: absolute;
        content: "";
        width: 81px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #b4b4b4;
        z-index: -1;
    }
    &::before {
        left: 0;
        content: "承認済";
    }
    &::after {
        right: 0;
        content: "未承認";
    }
}

.toggle-button_inner {
    position: absolute;
    width: 81px;
    height: 30px;
    top: 0;
    right: 0;
    background-color: $sub-color;
    border-radius: 15px;
    &::before {
        position: absolute;
        content: "未承認";
        color: #fff;
        font-size: 13px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.toggle-button:has(:checked) {
    .toggle-button_inner {
        background-color: $main-color;
        right: auto;
        left: 0;
        &::before {
            content: "承認済";
        }
    }
}

.toggle-button input {
    display: none;
}
