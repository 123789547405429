@charset "utf-8";
@use "../index" as *;

$fuwafuwa-func: cubic-bezier(.04,1.57,.27,.97);

/* --- Slidein Effects START --- */
/* --- It needs class="slidein" and "init" --- */
.c-slidein--left, .c-slidein--right {
	opacity: 1;
	transition: transform 1s ease, opacity 1s ease-in;
}
.c-slidein--left.init {
	opacity: 0;
	transform: translate(-10%, 0);
}
.c-slidein--right.init {
	opacity: 0;
	transform: translate(10%, 0);
}
/* --- Slide Effects END --- */


/* --- Slideup Effects without Buttons and links START --- */
/* --- It needs class="slideup" and "init"--- */
/* !! エフェクトを入れる要素に<a>, <button>など、クリッカブルな要素が含まれる場合は.c-slideup_jsを使用すること !! */
/* !! mac safariでクリッカブル領域のみ遅れて移動するバグがみられたため !! */
.c-slideup {
	opacity: 1;

	// ふわーっと出現
	// transition: transform 3s $fuwafuwa-func, opacity 0.5s ease-in-out;

	// シンプルに出現
	// transition: transform 0.8s ease-in-out, opacity 0.8s ease-in-out;
	// transition: transform 0.6s ease-in-out, opacity 0.6s ease-in-out;
	transition: opacity 0.7s ease-in-out,transform 1.5s cubic-bezier(0.35, 0.34, 0.22, 0.99);

	transform: translate(0, 0);
	will-change: transform, opacity;
	&.init {
		opacity: 0;
		// ふわーっと出現
		transform: translate(0, 100px);

		@include mq() {
			transform: translate(0, 80px);
		}

		// シンプルに出現
		// transform: translate(0, 50px);
	}
}
/* --- Slideup Effects END --- */

/* --- Slideup Effects with Buttons and links START --- */
/* --- It needs class="slideup" and "init"--- */
/* !!エフェクトを入れる要素に<a>, <button>がなければ.c-slideupを推奨 */
.c-slideup--js {
	opacity: 0;
	transform: translate(0, 150px);
	will-change: transform, opacity;
}
/* --- Slideup Effects END --- */

/* --- rotate Effects START --- */
/* --- It needs class="rotate" and "init"--- */
.c-rotate {
	  opacity: 1;
    filter: alpha(opacity=100);
    -webkit-transform: scale3d(1,1,1) rotate3d(0,0,0,0) translate3d(0,0,0);
    transform: scale3d(1,1,1) rotate3d(0,0,0,0) translate3d(0,0,0);
    transition: opacity 4s,-webkit-transform 4s;
    transition: opacity 4s,transform 4s;
    transition-timing-function: cubic-bezier(.19,1,.22,1);
	&.init {
		opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transform: scale3d(1.3,1.3,1) rotate3d(1,-3,-1,-36deg) translate3d(0,50px,130px);
    transform: scale3d(1.3,1.3,1) rotate3d(1,-3,-1,-36deg) translate3d(0,50px,130px);
	}
}
/* --- Slideup Effects END --- */

/* --- fadein Effects START --- */
/* --- It needs class="rotate" and "init"--- */
.c-fadein {
	  opacity: 1;
    transition: opacity 0.5s ease-in-out;
	&.init {
		opacity: 0;
	}
}
/* --- Slideup Effects END --- */

@include mq_up(sp) {
	.delay_100 {
		transition-delay: 100ms;
	}
	.delay_200 {
		transition-delay: 200ms;
	}
	.delay_300 {
		transition-delay: 300ms;
	}
	.delay_400 {
		transition-delay: 400ms;
	}

	.delay_400_0 {
		transition-delay: 400ms;
		@include mq(sp) {
			transition-delay: 0;
		}
	}
}

.c-clear_effect_sp {
	@include mq(sp) {
		transition: none !important;
		transform: none !important;
		opacity: 1 !important;
	}
}
.c-clear_effect_up_sp {
	@include mq_up(sp) {
		transition: none !important;
		transform: none !important;
		opacity: 1 !important;
	}
}
