@charset "utf-8";


//コンテンツ幅
%content-width{
	width: 95%;
	max-width: 1166px;
	margin: auto;
	position: relative;
}


// 行間（_reset.scssで使用）
$base-line-height: 1.45;
