@charset "utf-8";
@use "../index" as *;

// CookiePolicy
.c-cookiebox {

    z-index: 100;
    position: fixed;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    width: 100%;

    .c-cookiebox__inner {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
		margin: 0 auto;
		padding: 20px;
		color: #fff;
		width: 1000px;

		@include mq() {
			width: 90%;
			padding: 15px 0;
		}


		p {
			width: 850px;
			font-size: 12px;
			font-weight: normal;
			line-height: 1.8;

			@include mq() {
				font-size: 10px;
				line-height: 1.6;
			}

			a {
				color: #fff;
				text-decoration: underline;
			}
		}

		.cookiebox__inner-btn {
			display: inline-block;
			z-index: 1;
			position: relative;
			padding: 15px 20px;
			background: #fff;
			color: #000;
			font-size: 12px;
			font-weight: bold;
			line-height: 1.6;
			text-align: center;
			cursor: pointer;
			border: 2px solid #333;

			@include mq() {
				width: 50vw;
				margin: 5px auto 0;
				padding: 5px 20px;
			}

		}

    }
}