@charset 'utf-8';
@use "../index" as *;

.c-linkList {
    max-width: 1011px;
    margin: 30px auto 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    &__item {
        width: 25%;
        padding: 0 10px;
        @include mq {
            width: 50%;
            margin-bottom: 10px;
            padding: 0 5px;
        }
        a {
            display: block;
            text-align: center;
            border-radius: 5px;
            border: 1px solid $main-color;
            padding: 24px 10px 32px;
            @extend %font-size_20;
            font-weight: bold;
            border-top-width: 6px;
            color: $main-color;
            position: relative;
            @include mq {
                padding: 20px 10px 26px;
            }
            &::after {
                width: 12px;
                height: 12px;
                content: "";
                position: absolute;
                bottom: 10px;
                right: 10px;
                background: url(../img/common/icon-tri.svg) no-repeat center;
                background-size: contain;
            }
        }
        img {
            width: 36px;
            height: 36px;
            margin: 0 auto 12px;
            @include mq(sp) {
                font-size: 30px;
                height: 30px;
            }
        }
    }
}
