@charset "utf-8";
@use "../index" as *;

.p-statistics{
  &__sort{
    margin-bottom: 20px;
    form{
      display: flex;
      justify-content: center;
      @include mq{
        flex-direction: column;
        max-width: 380px;
        margin: 0 auto;
      }
    }
    p{
      font-size: 14px;
      margin-right: 20px;
      flex-shrink: 0;
      @include mq(sp){
        margin-right: 10px;
      }
    }
    .term{
      display: flex;
      align-items: center;
      margin-right: 48px;
      @include mq{
        margin-right: 0;
      }
      div{
        display: flex;
        align-items: center;
      }
      input[type="date"]{
        width: 145px;
        position: relative;
        @include mq(sp){
          width: 135px;
        }
      }
      input[type="date"]::-webkit-calendar-picker-indicator {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: auto;
        height: auto;
        background: transparent;
        z-index: 1;
      }

      input[type="date"]::after {
        content: '';
        background-image: url(../img/common/icon-calender.svg);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: 0 0;
        width: 18px;
        height: 18px;
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
      }
      span{
        margin: 0 10px;
        @include mq(sp){
          margin: 0 5px;
        }
      }
    }
    .unit{
      display: flex;
      align-items: center;
      ul{
        display: flex;
        align-items: center;
      }
    }

    button{
      width: 106px;
      text-align: center;
      padding: 12px;
      justify-content: center;
      color: #fff;
      background-color: $main-color;
      border-radius: 5px;
      @include mq{
        margin: 10px auto;
      }
    }
  }
  &__table{
    min-width: inherit;
  }
}
