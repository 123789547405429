@charset "utf-8";

//設定・Mixin
@use "index";

//ベース
@use "foundation";

//プラグイン
// @import "plugin";

//共通パーツ
@use "component";

//レイアウト
@use "layout";

//Project/Page
@use "project";

//utility
@use "utility";

//FONT IMPORT
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Noto+Sans+JP:wght@400;700&display=swap');
