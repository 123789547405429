@charset "utf-8";
@use "../index" as *;


// パンくずリスト
div.c-breadcrumbs {
	width: 100%;
	height: 3.7em;
	margin: 0;
	padding: 1em 0;
	background-color: #fff;
	border-top: $border-color 1px solid;

	position: relative;
	@extend %font-size_12;

	overflow-x: auto;
	overflow-y: hidden;
	-webkit-overflow-scrolling: touch;
	overflow-scrolling: touch;

	/*スクロールバー非表示*/
	/* IE, Edge 対応 */
	-ms-overflow-style: none;
	/* Firefox 対応 */
	scrollbar-width: none;
	/* Chrome, Safari 対応 */
	&::-webkit-scrollbar {
		display:none;
	}


	#breadcrumbs {
		margin: auto;
		white-space: nowrap;

		a {
			color: $main-color;
			background-image: url(../img/arrow/arrow_right_c.svg);
			background-position: center right 5px;
			background-size: 13px 13px;
			padding: 0 22px 0 0;
			font-weight: bold;
		}

		img {
			margin: 1px 5px;
			vertical-align: text-bottom;
			width: 14px;
			height: 13px;
		}
	}
}
