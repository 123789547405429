@charset "utf-8";
@use "../index" as *;

// ------------ FRONT ------------

// トップページ
.p-front {
}

.p-front__fv {
  width: 100%;
  position: relative;
  overflow-x: hidden;

  .block {
    img {
      width: 100%;
    }
  }

  // 前・次ボタンの設定
  .swiper-button-next,
  .swiper-button-prev {
    width: 5%;
    height: 100%;
    position: absolute;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 30px 30px;
    top: 50%;
    transform: translateY(-50%);

    @include mq() {
      width: 10%;
    }
  }

  .swiper-button-next::after,
  .swiper-button-prev::after {
    content: "";
  }

  // 前ボタンの設定
  .swiper-button-prev {
    left: 0;
    background-image: url(../img/arrow/arrow_left_w.svg);
  }

  // 次ボタンの設定
  .swiper-button-next {
    right: 0;
    background-image: url(../img/arrow/arrow_right_w.svg);
  }

  // docボタンの設定
  .swiper-pagination {
    bottom: 10px;

    .swiper-pagination-bullet {
      width: 10px;
      height: 10px;
      opacity: 1;

      border-radius: 50%;
      background-color: $bg-color;
      @include transitions();

      &:hover {
        background-color: $main-color;
      }

      &.swiper-pagination-bullet-active {
        background-color: $main-color;
      }
    }
  }
}
