@charset "utf-8";
@use "../index" as *;

// ------------ FOOTER ------------*/

.l-footer {
  width: 100%;
  padding: 8px 0;
  margin: auto;
  background-color: #D9D9D9;
  text-align: center;

  a {
    @include link-color(#666);

    &:hover {
      text-decoration: underline;
    }
  }
  // Logo
  &__logo {
    padding: 0;

    img {
      width: 80%;
      max-width: 180px;
      height: auto;
      margin: auto;
    }
  }

  // サイトマップ
  &__sitemap {
    margin: auto;
    padding: 30px 0 20px;
    @include mq() {
      width: 100%;
    }
  }
  &__list {
    display: flex;
    justify-content: space-between;
    @include mq() {
      flex-wrap: wrap;
    }
  }
  &__item {
    @include mq() {
      width: 50%;
      border-bottom: 1px solid $border-color;
      &:nth-child(1) {
        border-top: 1px solid $border-color;
      }
      &:nth-child(2) {
        border-top: 1px solid $border-color;
      }
      &:nth-child(2n-1) {
        border-right: 1px solid $border-color;
      }
    }
    a {
      font-weight: 700;
      @include mq() {
        padding: 20px 15px;
        display: block;
        text-align: left;
      }
      @include mq(ssp) {
        padding: 15px 10px;
      }
    }
  }

  // ドキュメントリンク
  .copyright{
    font-size: 10px;
  }
}
