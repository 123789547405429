@charset "utf-8";
@use "../index" as *;

// privacy policy

.c-policyBox {
  &__content {
    padding: 20px;
    text-align: left;
    h2 {
      font-weight: 700;
      margin-bottom: 1em;
    }

    h3 {
      font-weight: 700;
      font-size: 14px;
    }

    p {
      margin-bottom: 1em;
      font-size: 12px;

      &.mb_0 {
        margin-bottom: 0;
      }
      // strong {
      // 	font-weight: 700;
      // }
    }

    ol {
      list-style-type: decimal;
      list-style-position: inside;
      margin-bottom: 1em;
      font-size: 12px;

      li {
        ol {
          padding-left: 2em;
          margin-bottom: 0;
          // margin: 1em 0;
        }
      }

      &.pl_25 {
        padding-left: 2em;
        // margin-top: 1em;
      }

      // @include mq(ssp) {
      // 	margin-bottom: 2em;
      // 	li {
      // 		ol {
      // 			padding-left: 1.5em;
      // 		}
      // 	}
      // }
    }
  }

  @include mq() {
    width: 100%;
    margin: 20px 0;
    .policy_content {
      padding: 20px;
      font-size: 12px;
    }
  }
  @include mq(sp) {
    // width: 90%;
    height: 120px;
    margin: 20px auto;
    .policy_content {
      padding: 10px;
    }
  }
}
