@charset "utf-8";
@use "../index" as *;

// ------------ COMMON HEADER ------------

.l-header {
    width: 100%;
    min-width: 1000px;
    @include mq {
        min-width: inherit;
    }

    &__top {
        height: 80px;
        background-color: $main-color;
        color: #fff;
        padding: 0 56px;
        position: relative;
        @include mq {
            height: 100%;
            padding: 0 17px;
        }
    }
    &__bottom {
        padding: 0 35px;
        height: 50px;
        background-color: #fff;
    }
    &__inner {
        margin: auto;

        @include mq() {
            height: 60px;
        }
    }

    // Logo
    &__logo {
        a {
            display: block;
            text-transform: uppercase;
            color: #fff;
            @extend %font-size_30;
            img {
                width: 248px;
                height: auto;
                @include mq {
                    width: 170px;
                }
            }
        }
    }
}

// ------------　スクロール後FIXED設定 ------------

header.fixed {
    background: rgba(255, 255, 255, 0.7);
    position: fixed;
    z-index: 100;
}
