@charset 'utf-8';
@use "../index" as *;

// ------------ BASS LAYOUT ------------

// 1カラムレイアウト設定
// TOP含む全ページ共通
.l-sec {
  padding-top: 40px;
  padding-bottom: 40px;

  @include mq() {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  &:first-child {
    padding-top: 80px;

    @include mq() {
      padding-top: 30px;
    }
  }
  &:last-child {
    padding-bottom: 80px;

    @include mq() {
      padding-bottom: 30px;
    }
  }
}
