@charset "utf-8";
@use "../index" as *;

.c-modalDelete {
    position: fixed;
    left: 0;
    top: 0;
    background: rgba($color: #56565687, $alpha: 0.53);
    width: 100%;
    height: 100%;
    display: none;
    &__box {
        padding: 62px 20px;
        margin: 0;
        width: 90%;
        max-width: 803px;
        border-top: 9px solid $main-color;
        position: fixed;
        top: 50%;
        left: 50%;
        background: rgb(255, 255, 255);
        transform: translate(-50%, -50%);
    }
    &__inner {
        padding-top: 73px;
        background: url(../img/common/icon-warning.svg) no-repeat center top;
        background-size: 68px auto;
        @include mq {
            padding-top: 60px;
            background-size: 52px auto;
        }
        table {
            max-width: 500px;
            margin: 0 auto 20px;
        }
    }
    p {
        @extend %font-size_36;
        font-weight: bold;
        letter-spacing: 0.16px;
        margin-bottom: 40px;
        text-align: center;
        @include mq {
            margin-bottom: 20px;
        }
    }

    &__flex {
        display: flex;
        justify-content: center;
        button {
            width: 192px;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            border-radius: 5px;
            margin: 0 10px;
            cursor: pointer;
            &.close {
                background-color: #bfbfbf;
            }
            &.delete {
                background-color: $sub-color;
            }
            @include mq {
                width: 160px;
                height: 40px;
            }
        }
    }
}
