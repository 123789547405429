@charset "utf-8";
@use "../index" as *;

//後ろの要素固定
body.drawer_open {
    overflow: hidden;
    -ms-touch-action: none;
    touch-action: none;
}

// ハンバーガーの設定
.l-headerToggle {
    display: none;

    @include mq() {
        position: absolute;
        z-index: 1001;
        top: 50%;
        right: 11px;
        display: block;
        box-sizing: content-box;
        width: 24px;
        padding: 15px 7px 15px;
        transform: translateY(-50%);
        border: 0;
        outline: 0;
        background-color: transparent;
    }
}
//ハンバーガーボタン
.l-headerToggle__bar {
    background-color: #fff;
    display: block;
    width: 100%;
    height: 2px;
    border: 0;
    -webkit-transition: background-color 0.35s ease, transform 0.35s ease;
    transition: background-color 0.35s ease, transform 0.35s ease;
}

.l-headerToggle__bar + .l-headerToggle__bar {
    margin-top: 7px;
}

//閉じるボタン
.l-headerToggle.toggle_active {
    .l-headerToggle__bar {
        background-color: #fff;
    }
    .top {
        -webkit-transform: translateY(11px) translateX(0) rotate(45deg);
        transform: translateY(11px) translateX(0) rotate(45deg);
        width: 24px;
    }
    .middle {
        opacity: 0;
    }
    .bottom {
        -webkit-transform: translateY(-7px) translateX(0) rotate(-45deg);
        transform: translateY(-7px) translateX(0) rotate(-45deg);
        width: 24px;
        margin-left: 0;
    }
}

//開いた時の背景
.drawer_bg {
    display: none;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    background-color: rgba(51, 51, 51, 0.5);
}

.l-header-nav {
    @include mq() {
        display: none;
        opacity: 0;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-overflow-scrolling: touch;

        //開く時の動き
        &.is_open {
            display: block;
            top: 0;
            opacity: 1000;
            animation-duration: 0.4s;
            //アニメーションの種類
            animation-name: fadeInRight;
        }

        //閉じる時の動き（何もしないとパッと閉じる）
        &.is_close {
            // animation-duration: 0.4s;
            // //アニメーションの種類
            // animation-name: fadeOutRight;
        }
    }
}

/**
 * drawer animation
 */

//右から
@keyframes fadeInRight {
    from {
        opacity: 0;
        transform: translate3d(100%, 0, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}
@keyframes fadeOutRight {
    from {
        opacity: 0;
        transform: translate3d(0, 0, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(100%, 0, 0);
    }
}

//左から
@keyframes fadeInLeft {
    from {
        opacity: 0;
        transform: translate3d(-100%, 0, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}
@keyframes fadeOutLeft {
    from {
        opacity: 0;
        transform: translate3d(0, 0, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(-100%, 0, 0);
    }
}

//上から
@keyframes fadeInDown {
    from {
        opacity: 0;
        transform: translate3d(0, -100%, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}
@keyframes fadeOutDown {
    from {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }

    to {
        opacity: 0;
        transform: translate3d(0, -100%, 0);
    }
}

//下から
@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translate3d(0, 100%, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}
@keyframes fadeOutUp {
    from {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }

    to {
        opacity: 0;
        transform: translate3d(0, 100%, 0);
    }
}

//大きく
@keyframes fadeInScale {
    from {
        opacity: 0;
        transform: scale(0.9);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes fadeOutScale {
    from {
        opacity: 1;
        transform: scale(1);
    }
    to {
        opacity: 0;
        transform: scale(0.9);
    }
}
