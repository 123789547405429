@charset 'utf-8';
@use "../index" as *;

// 2カラムレイアウト設定
// TOP含む全ページ共通
.l-page {
  &--column_2 {
    padding-top: 50px;

    @include mq_up {
      display: flex;
      justify-content: space-between;
    }

    @include mq() {
      padding-top: 20px;
    }
    .l-page__main {
      width: 70%;

      @include mq() {
        width: 100%;
      }
    }

    // サイドバー
    .l-sidebar {
      width: 25%;

      @include mq() {
        width: 100%;
      }
    }
  }

  &__main{
    padding: 50px 0 70px;
    @include mq{
      padding: 0;
    }
  }

  &__box{
    background-color: #fff;
    padding: 40px 30px 60px;
    width: 96%;
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
    
    @include mq{
      margin: 0;
      width: 100%;
      padding: 40px 20px;
    }
  }
}
