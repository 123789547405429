@charset "utf-8";

@mixin objImg($ratio){
    position: relative;
    width: 100%;
    overflow: hidden;
    &:before{
        content: "";
        display: block;
        height: 0;
        padding-top: $ratio;
    }
    img{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
    }
}