@charset "utf-8";
@use "../../index" as *;

.c-articleNav {
  width: 100%;
  height: auto;
  padding: 10px 0;
}

// 前後の記事
ul.c-articleNav__transfer {
  width: 100%;
  max-width: 500px;
  margin: auto;
  display: table;
  table-layout: fixed;
  text-align: center;
  padding: 30px 0;

  li {
    display: table-cell;
    vertical-align: middle;
    padding: 5px 0;
    font-weight: bold;
    color: $border-color;
    @extend %font-size_14;

    & + li {
      border-left: $border-color 1px solid;
    }

    a {
      background-repeat: no-repeat;
      background-size: 20px 20px;
      padding: 5px 25px;

      @include mq(sp) {
        padding: 5px 15px;
      }
    }

    &:first-child {
      a {
        background-image: url(../img/arrow/arrow_left_c.svg);
        background-position: left center;
        padding: 5px 5px 5px 45px;

        @include mq(sp) {
          background-image: none;
          padding: 5px 15px;
        }
      }
    }

    &:last-child {
      a {
        background-image: url(../img/arrow/arrow_right_c.svg);
        background-position: right center;
        padding: 5px 45px 5px 5px;

        @include mq(sp) {
          background-image: none;
          padding: 5px 15px;
        }
      }
    }
  }
}
