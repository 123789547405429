@charset "utf-8";
@use "../index" as *;

.c-listNav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 0 12px;
    @include mq {
        flex-direction: column;
        &:last-of-type {
            padding-top: 32px;
        }
    }
    p {
        font-size: 14px;
        @include mq {
            font-size: 12px;
            margin-bottom: 10px;
        }
    }
}
