@charset 'utf-8';
@use "../index" as *;

.c-sidebarBlock {
  padding: 30px 0;
  border-bottom: $border-color 2px solid;

  @include mq(sp) {
    padding: 20px 0;
  }

  &:first-child {
    padding: 0 0 30px 0;

    @include mq(sp) {
      padding: 0 0 20px 0;
    }
  }

  &:last-child {
    border-bottom: none;
  }

  &__ttl {
    margin: 0 auto 10px auto;
    font-weight: bold;
    @extend %font-size_18;
  }
}
