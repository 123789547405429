@charset "utf-8";
@use "../index" as *;
/*================================
 * URL一覧/分析ページの検索ボックス
 *================================*/
.c-search{
    background: $bg-color;
    padding: 30px;
    border-radius: 10px;
    @include mq(){
        padding: 15px;
    }

    input[type=date] {
        width: 145px;
        position: relative;
    }
    &__submit{
        text-align: center;
        padding-top: 15px;
        margin-top: 15px;
        border-top: $border-color 1px solid;
    }
    &__submit-btn{
        display: inline-block;
        width: auto;
        min-width: 160px;
        max-width: 100%;
        margin: 0 auto;
        text-align: center;
        padding: 12px 50px;
        font-weight: bold;
        border-radius: 5px;
        color: #fff;
        background-color: #3b6bd3;
        border: none;
        cursor: pointer;
    }
}