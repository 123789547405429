@charset "UTF-8";

// ---- テーマの基本スタイル ----

// メインカラー
$main-color: #3B6BD3;
$sub-color: #D24F4F;
$err-color: #de2636;   // フォームのエラー表示の色として設定

// 標準背景色
$bg-color: #EEEEEE;   // フッターやセクションの背景色としてよく使用する色を設定

// 標準フォントカラー
$base-font-color: #000;

// 標準ボーダーカラー
$border-color: #DFD6D6;   // フォームのテーブル、hrなどのボーダーカラー

// ボックス
$box: #e1e1e1;   // フォームのテーブルthの背景色など
