@charset "utf-8";
@use "../../index" as *;

// 投稿一覧（3列）
.c-articleGrid {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px 40px;

  @include mq(sp) {
    margin: 0 -5px 20px;
  }
  @include mq(ssp) {
    margin: 0 0 20px;
  }

  &__bgBox {
    display: block;
    border: solid 1px;
    &:hover {
      opacity: 0.8;
      text-decoration: none;
      img {
        opacity: 1;
      }
    }
  }

  &__item {
    width: 33.33%;
    padding: 0 8px;
    margin-bottom: 20px;
    @include mq(sp) {
      width: 50%;
      padding: 0 5px;
    }
    @include mq(ssp) {
      width: 100%;
    }
  }

  &__thum {
    position: relative;
    @include objImg(66.67%);

    .pop {
      z-index: 2;
      position: absolute;
      top: 0;
      left: 0;
      background-color: #c94447;
      color: #fff;
      padding: 0.5em 0.7em;
      border-radius: 0 0 10px 0;
      letter-spacing: 1px;
      font-weight: 700;
      font-size: 12px;
    }
  }

  &__cont {
    padding: 15px 15px 20px;
  }
  &__ttl {
    font-weight: 700;
    @extend %font-size_16;
    // タイトルは2行のみ表示。文末に3点リーダーを挿入。
    // 表示したい行数とline-height値（emまたはpxの単位あり）を要設定
    @include textend-dots(2, $base-line-height);
  }

  &__info {
    li.article-day {
      width: 100%;
      @extend %font-size_14;
      font-weight: 400;
      & + li {
        margin-left: 0;
      }
    }
    ul.article-cgy {
      li {
        a,
        span {
          @extend %font-size_12;
          background-size: 10px auto;
          padding: 2px 10px;
        }
      }
    }
  }

  &__text {
    // 本文は3行のみ表示。文末に3点リーダーを挿入。
    // 表示したい行数とline-height値（emまたはpxの単位あり）を要設定
    @include textend-dots(3, $base-line-height);
    @extend %font-size_14;
    padding: 0;
  }
}
