@charset "utf-8";
@use "../../index" as *;

.c-articleTag {
  &__item {
    display: inline-block;
    margin: 0 5px 10px 0;

    a {
      width: 100%;
      height: 100%;
      display: block;
      border-radius: 5px;
      background: $main-color;
      padding: 5px;

      // リンク設定
      &:link,
      &:visited,
      &:active {
        color: #fff;
        background-color: $main-color;
        @extend %font-size_12;
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }
}
