@charset "utf-8";
@use "../../index" as *;

// SNSシェアボタン
.c-shareBox {
  text-align: center;
  &__bg {
    display: inline-block;
    width: auto;
    background-color: $bg-color;
    padding: 20px 60px;
    border-radius: 50px;

    @include mq() {
      padding: 20px 40px;
    }

    @include mq(sp) {
      display: block;
      padding: 15px;
    }
  }
  &__list {
    display: flex;
    justify-content: center;
    align-items: center;
    li + li {
      margin-left: 20px;
    }
    li:first-of-type {
      margin-right: 15px;
    }
    p {
      color: $main-color;
      @extend %font-size_20;
      letter-spacing: 0.05em;
      font-weight: bold;
    }
    a {
      display: block;
    }

    @include mq {
      li + li {
        margin-left: 25px;
      }
      li:first-of-type {
        margin-right: 10px;
      }
    }
  }

  @include mq(sp) {
    .icon_twitter {
      width: 25px;
    }
    .icon_facebook,
    .icon_line {
      width: 27px;
    }
  }
}
