@charset "utf-8";
@use "../index" as *;



// 共通セクションタイトル
.c-ttl {
	margin: 0 auto 10px auto;
	font-weight: bold;
	@extend %font-size_26;
	text-align: center;
}