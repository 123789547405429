@charset "utf-8";
@use "../index" as *;

// text align
.u-text-left {
	text-align: left;
}

.u-text-right {
	text-align: right;
}

.u-text-center {
	text-align: center !important;
}

.u-text-center_left {
	text-align: center;
	@include mq() {
		text-align: left;
	}
}

.u-text_center {
	text-align: left;
	@include mq() {
		text-align: center;
	}
}
