@charset "utf-8";
@use "../index" as *;

// ------------ FORM BASS ------------
input,
select,
textarea,
search {
    font-family: $base-font-family;
    border: #b4b4b4 1px solid;
    background-color: #fff;
    -webkit-appearance: none;
    font-size: 16px; // SPでは16px以上を指定すること（フォーカス時のズーム防止）
    border-radius: 5px;
}

button {
    font-family: $base-font-family;
    background-color: transparent;
    @extend %font-size_16;
    border: none;
}

// FOCUS
input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: 0;
    border-color: $main-color;
}

// PLACEHOLDER
:-ms-input-placeholder {
    color: #ddd;
}
::placeholder {
    color: #ddd;
}

// Chorme
::selection {
    color: #fff;
    background: $main-color;
}

input::selection,
textarea::selection {
    color: #fff;
    background: $main-color;
}

// ------------ INPUT STYLE ------------

input {
    width: 100%;
    padding: 8px 10px;
    margin: 5px 0;
}

// 長さ設定
input.wth_80 {
    width: 80%;
    @include mq() {
        width: 100%;
    }
}
input.wth_70 {
    width: 70%;
    @include mq() {
        width: 100%;
    }
}
input.wth_50 {
    width: 50%;
    @include mq() {
        width: 100%;
    }
}
input.wth_30 {
    width: 30%;
    @include mq() {
        width: 100%;
    }
}
input.wth_20 {
    width: 20%;
    @include mq() {
        width: 50%;
    }
}

// テキストエリア
textarea {
    width: 100%;
    height: 82px;
    padding: 10px;
    margin: 5px 0;
    resize: vertical;
}

// セレクト
select {
    width: 100%;
    padding: 10px 60px 10px 10px;
    background-image: url(../img/arrow/input_select.svg);
    background-position: right 10px center;
    background-size: 17px 17px;
    vertical-align: middle;
    border: #b4b4b4 1px solid;
    margin: 5px 0;
    border-radius: 5px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    @include mq {
        width: 100%;
    }
}

// IE
select::-ms-expand {
    display: none;
}

// ラジオボタン チェックボックス
input[type="radio"],
input[type="checkbox"] {
    display: none;
}

// 未選択時
input[type="radio"] + label {
    padding: 7px 0px 7px 21px;
    margin: 2px 24px 2px 0px;
    display: inline-block;
    background-image: url(../img/arrow/input_check_off.svg);
    background-position: left 50%;
    background-size: 14px 14px;
    cursor: pointer;
    font-size: 14px;
}

// 選択時
input[type="radio"]:checked + label {
    background-image: url(../img/arrow/input_check_on.svg);
    background-position: left 50%;
    background-size: 14px 14px;
}

// 未選択時
input[type="checkbox"] + span,
input[type="checkbox"] + label {
    padding: 7px 0px 7px 25px;
    margin: 2px 20px 2px 0px;
    background-image: url(../img/arrow/checkbox_off.svg);
    background-position: left 50%;
    background-size: 18px 18px;
    display: inline-block;
    font-size: 14px;
    cursor: pointer;
}

// 選択時
input[type="checkbox"]:checked + span,
input[type="checkbox"]:checked + label {
    background-image: url(../img/arrow/checkbox_on.svg);
    background-position: left 50%;
    background-size: 18px 18px;
}

// ファイルアップローダ
input[type="file"] {
    border: none;
    padding: 0;
}

// 送信ボタン
input[type="submit"],
input[type="reset"],
input[type="button"],
button[type="submit"],
button[type="button"] {
    &.input_submit {
        display: inline-block;
        width: auto;
        min-width: 220px;
        max-width: 100%;
        margin: 0 auto;
        text-align: center;
        padding: 12px 50px;
        font-weight: bold;
        border-radius: 48px;
        color: #fff;
        background-color: $main-color;

        border: none;
        cursor: pointer;

        &:hover {
            opacity: 0.8;
        }
    }

    &.button_confirm {
        display: inline-block;
        margin: 0 auto;
        width: auto;
        min-width: 220px;
        text-align: center;
        padding: 12px 50px;
        font-weight: bold;
        border-radius: 48px;
        color: #fff;
        background-color: $main-color;

        border: none;
    }

    &.input_return {
        display: inline-block;
        width: auto;
        border: none;
        padding: 0 0 0 15px;
        background-image: url(../img/arrow/arrow_left.svg);
        background-size: 5px auto;
        background-position: left center;
        min-width: inherit;
        color: $base-font-color;
        font-weight: 500;
        text-decoration: underline;
        background-color: transparent;
        transition: none;
        cursor: pointer;

        @include mq(ssp) {
            width: auto;
        }
    }
    &.button_reset {
        color: $main-color;
        margin-top: 27px;
        font-size: 14px;
        text-decoration: underline;
        cursor: pointer;
    }
}

p#fm_ajax_message {
    font-weight: bold;
    color: $main-color;
    letter-spacing: 1px;
    @extend %font-size_16;
}
