@charset "utf-8";
@use "../index" as *;


// よくあるご質問
.c-faq {

	text-align:left;

	p {
		display:inline;

		@include mq() {
			display: block;
		}
	}

	span {
		display: inline-block;
		text-align: center;
		background-color: $main-color;
		color: #FFF;
		padding: 2px 15px;
		margin: 0 10px 0 0 ;
		font-weight: bold;
		border-radius: 100px;
		@extend %font-size_12;

		@include mq() {
			margin: 0 0 5px 0 ;
		}
	}
	dt {
		cursor:pointer;
		font-weight: bold;
		padding: 20px 0;
		border-bottom: $border-color 1px solid;

		p {
			padding: 0 35px 0 0;

			background-image: url(../img/arrow/arrow_open.svg);
			background-position: right center;
			background-size: 20px 20px;

			@include mq() {

			}
		}

		&:hover {

			p {
				text-decoration: underline;
			}
		}

		&.open {

			p {
				background-image: url(../img/arrow/arrow_close.svg);
				background-position: right center;
				background-size: 20px 20px;
			}
		}
	}

	dd {
		display:none;
		line-height:1.5em;

		div.detail {
			padding:30px 0 30px 60px;

			@include mq(sp) {
				padding:10px 0 20px 10px;
			}

			img {
				width:90%;
				max-width:650px;
				margin:20px auto 15px auto;
			}

			p {
				span {
					color:$border-color;
				}
			}
		}
	}

}
