@charset "utf-8";
@use "../index" as *;


// メッセージボックス（フォームの送信完了画面と404ページで使用）
div.c-basket__box {
	max-width: 700px;
	height: auto;
	margin: auto;
	text-align: center;
	padding: 100px 0;

	@include mq(sp) {
		padding: 50px 0;
	}

	h1, h2 {
		padding: 0 0 20px;
		margin: 0 0 20px 0;
		text-align: center;
		font-weight: bold;
		@extend %font-size_30;
		border-bottom: #DADADA 1px solid;

		// 見出しにイラストが入る場合は、背景画像としてCSSで設定すること！
		// background-image: url();
		background-position: center top;
		background-repeat: no-repeat;
		background-size: auto;
	}

	p {
		padding: 0 0 30px 0;
		font-weight: bold;
		@extend %font-size_16;
	}
}
