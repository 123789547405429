@charset "utf-8";
@use "../index" as *;

// ------------ NAV LAYOUT ------------

.l-header-nav {
    margin: auto;
    position: absolute;
    right: 27px;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);

    // 折りたたみ時
    @include mq() {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        max-width: 375px;
        overflow: auto;
        background-color: $main-color;
        z-index: 1000;
        -webkit-transform: none;
        transform: none;
    }
    &__list {
        display: flex;

        // 折りたたみ時
        @include mq() {
            flex-direction: column;
            width: 100%;
            padding: 0 25px;
        }
    }
    &__item {
        // 折りたたみ時
        @include mq() {
            width: 100%;
        }

        a {
            display: block;
            width: 100%;
            height: 100%;
            padding: 14px 23px;
            color: #fff;
            font-size: 18px;

            // 折りたたみ時
            @include mq() {
                padding: 15px 0;
                font-size: 16px;
            }
        }
        &--account {
            > a {
                font-size: 14px;
                display: flex;
                align-items: center;
                &::before {
                    content: "";
                    width: 25px;
                    height: 25px;
                    background: url(../img/common/icon-account.svg) no-repeat
                        center;
                    background-size: contain;
                    margin-right: 9px;
                }
                span {
                    font-size: 18px;
                    margin-right: 6px;
                }
            }
        }

        &.switching {
            display: none;
            a {
                text-transform: uppercase;
                color: #fff;
                @extend %font-size_30;
            }

            // 折りたたみ時
            @include mq() {
                display: block;
            }

            // ロゴ
            img {
                width: 170px;
                height: auto;
                margin-top: 6px;
            }

            // ドキュメントメニュー
            ul {
                padding: 15px 0;

                li {
                    a {
                        padding: 3px 15px;
                        font-weight: normal;
                        border-bottom: none;
                    }
                }
            }
        }
    }
    &--bottom {
        .l-header-nav__item a {
            color: $base-font-color;
            font-size: 16px;
        }
    }
}

// サブメニュー（ドロップダウン）の親メニューのスタイル
.l-header-nav__item--parent {
    position: relative;
    @include mq {
        border-top: 1px solid #fff;
        margin-top: 15px;
    }
    > a {
        // &:after {
        //   content: "";
        //   display: inline-block;
        //   width: 20px;
        //   height: 20px;
        //   background-image: url(../img/arrow/input_select.svg);
        //   background-position: center right;
        //   background-size: 20px 20px;
        //   background-repeat: no-repeat;
        //   vertical-align: middle;
        // }
        @include mq {
            padding-top: 35px;
            padding-bottom: 12px;
            pointer-events: none;
        }
    }
}

// サブメニュー（ドロップダウン）
ul.l-header-nav__sub {
    position: absolute;
    left: 17px;
    top: 58px;
    z-index: 4;
    @include mq_up {
        /*形状を指定*/
        background: #fff;
        box-shadow: 0px 1px 8px 0px #adadad40;
        width: 154px;
        /*はじめは非表示*/
        visibility: hidden;
        padding: 16px;
        opacity: 0;
        /*アニメーション設定*/
        transition: all 0.3s;
    }
    @include mq {
        position: initial;
    }
    a {
        padding: 9px;
        color: $base-font-color;
        font-size: 15px;
        @include mq {
            color: #fff;
            padding: 12px 33px;
        }
    }
    @include mq_up {
        &::before {
            content: "";
            position: absolute;
            width: 11px;
            height: 11px;
            background: #fff;
            transform: rotateZ(45deg);
            left: 10px;
            top: -5px;
        }
    }
}
.l-header-nav__item--parent:hover ul.l-header-nav__sub {
    visibility: visible;
    opacity: 1;
}
