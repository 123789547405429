@charset "utf-8";
@use "../../index" as *;

// カテゴリ一覧
.c-categoryList {
  font-size: 0;

  &__item {
    margin-right: 5px;
    margin-bottom: 5px;
    display: inline-block;
    a,
    span {
      @extend %font-size_14;
      background-color: $sub-color;
      padding: 3px 10px;
      border-radius: 5px;
      color: #fff;
      background: $sub-color;
      font-weight: 700;
    }
    a {
      @include link-color(#fff);
      &:hover {
        opacity: 0.8;
        text-decoration: none;
      }
    }
  }
}
