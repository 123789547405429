@charset "utf-8";
@use "../index" as *;

// TABLE LAYOUT
.c-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;

  // table用クラス：~768pxでは縦1列表示にする
  &--tbBlock {
    @include tb_block();
  }

  // table用クラス：~568pxでは縦1列表示にする
  &--spBlock {
    @include mq(sp) {
      &,
      thead,
      tbody,
      tfoot,
      tr {
        display: block;
        width: 100%;
      }
      tr {
        th,
        td {
          display: block;
          width: 100%;
        }
      }
    }
  }
}
