@charset "utf-8";
@use "../index" as *;

// 角丸
.u-border--corners {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
}

// ボーダー
.u-border {
  border: 1px solid $border-color;
}
