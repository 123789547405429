@charset 'utf-8';
@use "../index" as *;

// ページタイトル
div.c-pageTitle {
    padding-bottom: 22px;
    text-align: center;
    @include mq {
        padding-bottom: 20px;
    }

    h1,
    p {
        font-weight: 700;
        letter-spacing: 0.16px;
        @extend %font-size_36;
    }

    span {
        margin-top: 5px;
        font-size: 12px;
        color: $sub-color;
    }
}
.c-pageTitle__txt {
    font-size: 14px;
    text-align: center;
    @include mq(sp) {
        font-size: 12px;
    }
}
